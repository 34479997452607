<template>
  <div class="text-h5">Termijnbedrag</div>
  <div class="text-caption text-grey-9" style="max-width: 70%">
    Je mag het termijnbedrag maximaal 10% verlagen en onbeperkt verhogen. Als de
    Prospect dit meer wilt verlagen of verhogen, kan hij/zij dit zelf doen in
    zijn/haar 'mijn' omgeving.
  </div>
  <div class="q-pt-sm text-caption" style="max-width: 70%">
    <q-toggle
      :disable="readonly"
      label="Geeft de Prospect toestemming om het termijnbedrag aan te passen?"
      color="green"
      v-model="manualInstallment"
      true-value="Ja"
      false-value="Nee"
    />
  </div>
  <q-input
    :readonly="readonly"
    outlined
    dense
    ref="manualInstallmentRef"
    :disable="manualInstallment != 'Ja'"
    style="max-width: 70%"
    :modelValue="
      manualInstallment == 'Ja' ? manualInstallmentAmount : installmentAmount
    "
    @change="manualInstallmentAmount = $event"
    :rules="manualInstallmentRules"
  />
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { validator } from "../../plugins/validation.js";

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  manualInstallmentRules: Array,
});

//* VueUse
const $store = useStore();

//* Refs
const manualInstallmentRef = ref(null);

//* Computed
const projectSpecific = computed(() => $store.getters.projectSpecific);
const manualInstallment = computed({
  get() {
    return projectSpecific.value["Termijnbedragaanpassen"] ?? "Nee";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Termijnbedragaanpassen",
      value: newVal,
    });

    if (newVal != "Ja") {
      manualInstallmentAmount.value = projectSpecific.value[
        "_ToAmMonth_"
      ].replace(".", ",");
    }
  },
});

const manualInstallmentAmount = computed({
  get() {
    return (
      (manualInstallment.value == "Ja"
        ? projectSpecific.value["voorschotbedrag"]
        : null) ??
      projectSpecific.value["_ToAmMonth_"]?.replace(".", ",") ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "voorschotbedrag",
      value: newVal.replace(".", ","),
      validated: validator(
        props.manualInstallmentRules,
        newVal.replace(".", ",")
      ),
    });
  },
});
</script>
<style lang="sass"></style>

import script from "./Offers.vue?vue&type=script&setup=true&lang=js"
export * from "./Offers.vue?vue&type=script&setup=true&lang=js"

import "./Offers.vue?vue&type=style&index=0&id=a58f8334&lang=sass"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QTable});

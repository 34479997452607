import script from "./InstallationDetails.vue?vue&type=script&setup=true&lang=js"
export * from "./InstallationDetails.vue?vue&type=script&setup=true&lang=js"

import "./InstallationDetails.vue?vue&type=style&index=0&id=e0e5f9f4&lang=sass"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QMarkupTable,QTooltip,QCheckbox,QStepperNavigation,QBtn});

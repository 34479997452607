<template>
  <q-card
    style="margin-top: -40px"
    :class="expandedTool != 'CustomerInformation' ? 'bg-grey-2' : ''"
  >
    <q-card-section
      class="text-center q-pa-none"
      @click="expandedTool = 'CustomerInformation'"
    >
      <q-btn
        class="q-pa-md q-pt-xl"
        flat
        no-caps
        :disable="toolDisabled || readonly"
        :ripple="false"
        style="width: 100%; height: 100%"
      >
        <!-- <q-icon name="location_city"></q-icon> -->
        <div class="text-h6 text-bold">Informatie van de Prospect</div>
      </q-btn>
      <!-- <div class="text-subtitle2">by John Doe</div> -->
    </q-card-section>

    <q-slide-transition>
      <div v-show="expandedTool == 'CustomerInformation' && !toolDisabled">
        <q-separator inset />
        <q-card-section class="text-caption text-center">
          Indien de Prospect een dubbele meter heeft, vul dan ook het laag
          verbruik in.
        </q-card-section>
        <q-card-section class="row q-pt-none">
          <div class="col-6 q-pr-sm q-mb-md">
            <q-input
              :readonly="readonly"
              dense
              outlined
              v-model="consumptionNormal"
              label="Normaal verbruik"
            />
          </div>
          <div class="col-6 q-pl-sm q-mb-md">
            <q-input
              :readonly="readonly"
              dense
              outlined
              v-model="consumptionLow"
              label="Laag verbruik"
            />
          </div>
          <div class="col-6 q-pr-sm">
            <q-input
              :readonly="readonly"
              dense
              outlined
              v-model="redelivery"
              label="Teruglevering"
            />
          </div>
          <div class="col-6 q-pl-sm">
            <q-input
              :readonly="readonly"
              dense
              outlined
              v-model="consumptionGas"
              label="Gas verbruik"
            />
          </div>
        </q-card-section>
        <q-card-section>
          <q-btn
            no-caps
            @click="saveConsumption"
            color="primary"
            style="width: 100%"
            >Opslaan</q-btn
          >
        </q-card-section>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const $store = useStore();

    const expandedTool = computed({
      get() {
        return $store.getters.expandedTool;
      },
      set(newVal) {
        $store.dispatch("setExpandedTool", newVal);
      },
    });

    const toolDisabled = computed(() => {
      if (
        $store.getters.projectSpecific["SuccesfullSJV"] == "Ja" ||
        $store.getters.projectSpecific["SuccesfullGPV"] == "Ja"
      ) {
        return true;
      }

      return false;
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const consumptionNormal = ref("");
    const consumptionLow = ref("");
    const consumptionGas = ref("");
    const redelivery = ref("");

    return {
      expandedTool,
      consumptionNormal,
      consumptionLow,
      consumptionGas,
      redelivery,
      toolDisabled,
      readonly,
    };
  },
  methods: {
    saveConsumption() {
      this.$store.dispatch("setProjectSpecificValues", {
        values: {
          "verbruik electra": this.consumptionNormal,
          "verbruik electra 2": this.consumptionLow,
          "verbruik gas": this.consumptionGas,
          "verbruik electra teruglevering": this.redelivery,
          verbruikBerekeningsType: "Klantinformatie",
          verbruikBerekeningAangepast: "Nee",
          centraalregister: "Nee",
        },
      });
    },
  },
};
</script>

<style lang="sass">
@import '../../styles/quasar.sass'
.consumption-item
    min-height: 30px
</style>

<template>
  <q-form ref="formRef_step1_2">
    <div class="q-pb-md">
      <EanList v-if="!manualEan && view != 'retention'" />
      <InstallationList v-if="!manualEan && view == 'retention'" />
      <ManualEan v-if="manualEan && installationCount" />
      <q-markup-table flat>
        <thead>
          <tr>
            <th class="text-left">Adres</th>
            <th class="text-center">Aansluitingen</th>
            <th class="text-right">Inhuizing/Verhuizing</th>
            <th class="text-right">
              Woont of werkt u op dit adres?
              <q-tooltip anchor="top middle" self="top middle">
                Here I am!
              </q-tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">
              {{ street }} {{ housenumber }}{{ housenumberExtension }},
              {{ postcode }} {{ city }}
            </td>
            <td class="text-center">{{ installationCount }}</td>
            <td class="text-right">
              <q-checkbox
                :disable="readonly"
                left-label
                v-model="moving"
                :true-value="false"
                :false-value="true"
              />
            </td>
            <td class="text-right">
              <q-checkbox :disable="readonly" left-label v-model="residence" />
            </td>
          </tr>
        </tbody>
      </q-markup-table>

      <q-stepper-navigation class="row justify-end">
        <q-btn
          no-caps
          @click="
            () => {
              nextStep();
            }
          "
          color="primary"
          label="Ga naar stap de volgende stap"
        />
      </q-stepper-navigation>
    </div>
  </q-form>
</template>

<script setup>
import { useQuasar } from "quasar";
import { computed, ref, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { validator } from "../plugins/validation.js";

const ManualEan = defineAsyncComponent(() =>
  import("@/components/InstallationDetails/ManualEan.vue")
);
const EanList = defineAsyncComponent(() =>
  import("@/components/InstallationDetails/EanList.vue")
);
const InstallationList = defineAsyncComponent(() =>
  import("@/components/InstallationDetails/InstallationList.vue")
);

//* VueUse
// eslint-disable-next-line no-unused-vars
const $q = useQuasar();
const $store = useStore();

//* Refs
const formRef_step1_2 = ref(null);

//* Computed
const view = computed(() => $store.getters.view);
const manualEan = computed(() =>
  view.value == "energyportal" ? true : $store.getters?.manualEan ?? false
);
const postcode = computed(() => $store.getters.record?.Postcode ?? "");
const street = computed(() => $store.getters.record?.Straat ?? "");
const housenumber = computed(() => $store.getters.record?.Huisnummer ?? "");
const city = computed(() => $store.getters.record?.Woonplaats ?? "");
const housenumberExtension = computed(
  () => $store.getters.record?.HuisnummerToev ?? ""
);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

const installationCount = computed(() => {
  if (
    typeof $store.getters.installationdetails.gas == "undefined" ||
    typeof $store.getters.installationdetails.electricity == "undefined"
  ) {
    return 0;
  }

  return (
    $store.getters.installationdetails.gas.length +
    $store.getters.installationdetails.electricity.length
  );
});

const installationElectricity = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Elektra"] != "" &&
        $store.getters.projectSpecific["EAN Elektra"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Elektra",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});

const installationGas = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Gas"] != "" &&
        $store.getters.projectSpecific["EAN Gas"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Gas",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});

const moving = computed({
  get() {
    return $store.getters.projectSpecific["contract_with_another_supplier"];
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "contract_with_another_supplier",
      value: newVal,
    });

    if (newVal == false) {
      $store.dispatch("setProjectSpecificValue", {
        key: "SwitchOrMove",
        value: "Move-In",
      });
    } else {
      $store.dispatch("setProjectSpecificValue", {
        key: "SwitchOrMove",
        value: "Switch-In",
      });
    }
  },
});

const residence = computed({
  get() {
    return $store.getters.projectSpecific["residential"];
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "residential",
      value: newVal,
    });

    if (newVal == true) {
      $store.dispatch("setProjectSpecificValue", {
        key: "verblijfsFunctie",
        value: "Ja",
      });
    } else {
      $store.dispatch("setProjectSpecificValue", {
        key: "verblijfsFunctie",
        value: "Nee",
      });
    }
  },
});

//* Methods
const runValidation = () => {
  if (view.value == "retention") {
    return true;
  }

  if (
    $store.getters.projectSpecific.MONO == "false" &&
    (!installationElectricity.value || !installationGas.value)
  ) {
    $q.notify({
      type: "negative",
      message: "Kies 2 EANs",
      position: "top",
    });
    return false;
  }

  if (
    (!installationElectricity.value || installationElectricity.value == "") &&
    (!installationGas.value || installationGas.value == "")
  ) {
    $q.notify({
      type: "negative",
      message: "Kies minimaal 1 EAN",
      position: "top",
    });
    return false;
  }

  if (installationElectricity.value == installationGas.value) {
    $q.notify({
      type: "negative",
      message: "De EAN nummers kunnen niet gelijk aan elkaar zijn",
      position: "top",
    });
    return false;
  }
  return true;
};

function nextStep() {
  if (!runValidation()) {
    return;
  }

  if (
    Object.entries($store.getters.poll).length != 0 ||
    $store.getters.pendingRequests > 0
  ) {
    $q.notify({
      type: "negative",
      message: "Wacht totdat we klaar zijn met verwerken.",
      position: "top",
    });
    return;
  }

  formRef_step1_2.value.validate().then((success) => {
    if (success) {
      $store.dispatch("incrementCurrentStep");

      $store.dispatch("send_message", { message: "save_ean_details" });
      $store.dispatch("updateStatus", {
        status: 1,
        reason: $store.getters.url,
      });
    }
  });
}
</script>

<style lang="sass">
th
  font-weight: 700 !important
</style>

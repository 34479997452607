<template>
  <div class="q-pb-sm row" style="margin-left: -36px">
    <div v-if="settings?.optinVerification ?? false" class="col q-px-md">
      <q-btn
        class="q-mx-auto"
        @click="
          sendType = { value: 'Optin', verification: 148 };
          compareOffer();
        "
        color="primary"
        :ripple="false"
        style="height: 70px; width: 100%"
        no-caps
        icon="mail"
      >
        Opt-In Verificatie
        <q-tooltip v-if="!isPhoneFilled" class="primary"
          >Geen 06-nummer, wordt verstuurd via email</q-tooltip
        >
      </q-btn>
    </div>
    <div v-if="settings?.email ?? true" class="col q-px-md">
      <q-btn
        class="q-mx-auto"
        @click="sendType = { value: 'Mail', status: 14, verification: 148 }"
        :color="sendType != 'Mail' ? 'grey-3' : 'primary'"
        :class="sendType != 'Mail' ? 'text-black' : ''"
        :ripple="false"
        style="height: 70px; width: 100%"
        no-caps
        icon="mail"
        >Email</q-btn
      >
    </div>
    <div v-if="settings?.sms ?? true" class="col q-px-md">
      <q-btn
        class="col-5 q-mx-auto q-mb-md"
        @click="
          sendType = { value: 'Mail+SMS', status: 142, verification: 145 }
        "
        :color="sendType != 'Mail+SMS' ? 'grey-3' : 'primary'"
        :class="sendType != 'Mail+SMS' ? 'text-black' : ''"
        :ripple="false"
        :disable="disableTextButton"
        style="height: 70px; width: 100%"
        no-caps
      >
        <q-icon name="mail" />
        <q-icon name="add" size="xs" />
        <q-icon name="question_answer" />
        Email + SMS</q-btn
      >
    </div>
    <q-slide-transition>
      <div
        v-show="showOffer"
        style="overflow: auto; height: 500px; width: 100%; border: none"
      >
        <iframe
          ref="offer_frame"
          style="overflow: auto; height: 500px; width: 100%; border: none"
        ></iframe>
      </div>
    </q-slide-transition>
    <q-slide-transition>
      <div
        v-show="showTerms"
        style="overflow: auto; height: 500px; width: 100%; border: none"
      >
        <iframe
          ref="terms_frame"
          style="overflow: auto; height: 500px; width: 100%; border: none"
        ></iframe>
      </div>
    </q-slide-transition>
    <div class="col-12">
      <q-stepper-navigation class="row justify-end">
        <q-btn
          v-show="view != 'multioffer'"
          color="primary"
          @click="showOffer = !showOffer"
          label="Bekijken"
          class="q-mx-xs"
          no-caps
        />
        <q-btn
          v-show="view != 'multioffer'"
          color="primary"
          @click="showTerms = !showTerms"
          label="Voorwaarden"
          class="q-mx-xs"
          no-caps
        />
        <q-btn
          color="primary"
          class="q-mx-xs"
          :disable="viewMode"
          @click="changeOrder()"
          label="Order aanpassen"
          no-caps
        />
        <q-btn
          color="primary"
          class="q-ml-xs"
          @click="compareOffer()"
          :loading="pendingRequests >= 1"
          :disable="
            (sendType == '' && !orderOnly) ||
            (readonly && statusId != '17' && statusId != '205')
          "
          :label="
            readonly && statusId == '17' ? 'Opnieuw verzenden' : 'Verzenden'
          "
          no-caps
          v-if="showSendButton"
        />
        <q-btn
          style="width: 75px"
          class="float-right q-ml-xs"
          color="primary"
          label="Afboeken"
          no-caps
          @click="afboeken()"
          :disable="writeOffDisabled || !emailSent"
          v-if="settings?.writeOff ?? false"
        />
      </q-stepper-navigation>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useQuasar, Dialog } from "quasar";
import { cc_Enabled, cc_GotoPage } from "../plugins/cc-integration.js";
import store from "../store/index.js";

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  settings: Object,
});

//* VueUse
const $store = useStore();
const $q = useQuasar();

//* Refs
const offer_frame = ref(null);
const terms_frame = ref(null);
const showOffer = ref(false);
const showTerms = ref(false);
const sendStatus = ref(14);
const requireVerification = ref(false);
const verificationStatus = ref(148);

//* Computed
const disableTextButton = computed(() => {
  const check = $store.getters.record.Telefoon.substring(0, 2);
  if (check == "06") {
    return false;
  }

  return true;
});

const view = computed(() => $store.getters.view);
const pendingRequests = computed(() => $store.getters.pendingRequests);
const offerText = computed(() => $store.getters.offerText);
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const recordStatus = computed(() => $store.getters.record?.status_id ?? 1);
const viewMode = computed(() => $store.getters.viewMode);
const termsText = computed(() => $store.getters.termsText);
const phone = computed(() => $store.getters.record.Telefoon);
const isPhoneFilled = computed(
  () => $store.getters.record.Telefoon != "" && $store.getters.record.Telefoon
);
const email = computed(() => $store.getters.record.Email);
const orderOnly = computed(() => $store.getters.projectSettings?.order_only);
const statusId = computed(
  () => $store.getters.record?.Status_ID ?? $store.getters.record?.status_id
);
const writeOffDisabled = computed(() => {
  const writeOffDisabledStatus = [1, 13, 154, 2, 3];
  if (writeOffDisabledStatus.indexOf(recordStatus.value) != -1) {
    return true;
  }

  return false;
});

const D2D = computed(() => {
  return $store.getters?.projectSpecific["D2D"] == "true" ? true : false;
});

const showSendButton = computed(() => {
  return (props.settings?.sms == false &&
    props.settings?.email == false &&
    orderOnly.value == false) ||
    pendingRequests >= 1
    ? false
    : true;
});

const emailSent = computed(() => {
  if (
    (props.settings?.email || props.settings?.sms) &&
    statusId.value != "17" &&
    !readonly.value
  ) {
    return false;
  }

  return true;
});

const message = ref(
  `De mail met daarin een link naar de aanbiedingspagina wordt nu verzonden naar ${email.value}.`
);

const sendType = computed({
  get() {
    return $store.getters.projectSpecific["verzendtype"] ?? "";
  },
  set(newVal) {
    if (newVal.value == "Mail+SMS") {
      message.value = `De mail met daarin een link naar de aanbiedingspagina wordt nu verzonden naar ${email.value} en de SMS wordt verzonden naar ${phone.value}.`;
    } else if (
      newVal.value == "Optin" &&
      phone.value != "" &&
      phone.value.slice(0, 2) == "06"
    ) {
      message.value = `Er wordt een SMS verzonden naar ${phone.value}.`;
      newVal.value = "SMS";
      newVal.status = 142;
    } else if (
      newVal.value == "Optin" &&
      (phone.value == "" || phone.value.slice(0, 2) != "06")
    ) {
      message.value = `Er wordt een mail verzonden naar  ${email.value}.`;
      newVal.value = "Mail";
      newVal.status = 14;
    }

    $store.dispatch("setProjectSpecificValue", {
      key: "verzendtype",
      value: newVal.value,
    });

    sendStatus.value = newVal.status;
    verificationStatus.value = newVal.verification;
  },
});

//* Methods
function updateStatus() {
  let loadingMessage = "Mails worden gegenereerd...";
  if (orderOnly.value == true) {
    sendStatus.value = 205;
    loadingMessage = "Order wordt gegenereerd...";
    message.value = "Order is aangemaakt en opgeslagen!";
  }
  $store
    .dispatch("updateRecord", {
      key: "Status_ID",
      value: sendStatus.value,
    })
    .then(() => {
      if (requireVerification.value) {
        $q.notify({
          type: "warning",
          message: message.value,
          position: "top",
          timeout: 10000,
          progress: true,
        });

        return;
      }

      $q.loading.show({
        message: loadingMessage,
      });
      if (view.value == "multioffer") {
        $store
          .dispatch("sendOffers")
          .then((response) => {
            if (response.data.error) {
              $q.notify({
                type: "negative",
                message: response.data.message,
                position: "top",
                timeout: 10000,
                progress: true,
              });
              $q.loading.hide();
              return;
            }
            $store.dispatch("getRecord");

            $q.loading.hide();
            $q.notify({
              type: "positive",
              message: message.value,
              position: "top",
              timeout: 10000,
              progress: true,
            });
          })
          .catch((err) => {
            if (err.response.data.error) {
              $q.notify({
                type: "negative",
                message: err.response.data.message,
                position: "top",
                timeout: 10000,
                progress: true,
              });
              $q.loading.hide();
              return;
            }
          });
        return;
      }
      if (view.value == "retention") {
        store.dispatch("setRedoAll", true);
        store.dispatch("setMessage", message.value);
        store.dispatch("send_message", { message: "start_sac_flow" });
      } else {
        $q.loading.hide();
        $q.notify({
          type: "positive",
          message: message.value,
          position: "top",
          timeout: 10000,
          progress: true,
        });
      }
    });
}

function compareOffer() {
  let verifyCheck = props.settings?.verify ?? true;
  let verify = "verifyAllOffers";

  if (view.value != "multioffer") {
    verify = "verifyOffer";
  }
  if (!verifyCheck) {
    updateStatus();
    return;
  }

  $store.dispatch(verify).then(() => {
    $store.dispatch("setCompareOfferWait", true);
  });
}

function checkSample() {
  requireVerification.value = false;

  $store.dispatch("checkSample").then((response) => {
    if (response.data.require_verification) {
      requireVerification.value = true;
      sendStatus.value = verificationStatus.value;

      message.value = `Na verificatie door TQIS wordt de mail met daarin een link naar de aanbiedingspagina verzonden naar ${email.value}.`;
      if (sendType.value == "Mail+SMS") {
        message.value = `Na verificatie door TQIS wordt de mail met daarin een link naar de aanbiedingspagina verzonden naar ${email.value} en de SMS wordt verzonden naar ${phone.value}.`;
      }
    }

    updateStatus();
  });
}

function changeOrder() {
  $store.dispatch("removeTQIS");
  $store
    .dispatch("updateRecord", {
      key: "Status_ID",
      value: 1,
    })
    .then(() => {
      $store.dispatch(
        "setCurrentStep",
        props?.settings?.change_order_step ?? 1
      );
    });
}

function afboeken() {
  if (!cc_Enabled.value) {
    location.href = process.env.VUE_APP_BASE_ENCODE_URL;
    return;
  }
  if (
    recordStatus.value == 1 ||
    recordStatus.value == 2 ||
    recordStatus.value == 3 ||
    recordStatus.value == 13 ||
    recordStatus.value == 29 ||
    recordStatus.value == 154
  ) {
    const message =
      "Let op! Je hebt het aanbod niet verstuurd en gaat het record niet positief afboeken. Dit record wordt niet voor verificatie aangeboden bij TQIS.\r\nJe kunt nog een terugbelafspraak maken. Weet je zeker dat je verder wilt gaan?";
    Dialog.create({
      title: "Confirm",
      message,
      cancel: true,
      persistent: true,
    }).onOk(() => {
      cc_GotoPage("portalclosednegative");
    });
  } else if (
    recordStatus.value == 14 ||
    recordStatus.value == 148 ||
    recordStatus.value == 145 ||
    recordStatus.value == 17 ||
    recordStatus.value == 15 ||
    recordStatus.value == 19 ||
    recordStatus.value == 18 ||
    recordStatus.value == 6 ||
    recordStatus.value == 7 ||
    recordStatus.value == 117 ||
    recordStatus.value == 151 ||
    recordStatus.value == 118 ||
    recordStatus.value == 143 ||
    recordStatus.value == 142 ||
    recordStatus.value == 145 ||
    recordStatus.value == 107 ||
    recordStatus.value == 118 ||
    recordStatus.value == 173 ||
    recordStatus.value == 176 ||
    recordStatus.value == 179 ||
    recordStatus.value == 185
  ) {
    cc_GotoPage("portalclosedpositive");
  }
}

//* Conditions
if (sendType.value == "Mail+SMS") {
  sendStatus.value = 142;
}
if (view.value != "multioffer") {
  $store.dispatch("getContent", 13).then(() => {
    $store.dispatch("fetchRecord").then(() => {
      offer_frame.value.contentWindow.document.open();
      offer_frame.value.contentWindow.document.write(offerText.value);
      offer_frame.value.contentWindow.document.close();

      terms_frame.value.contentWindow.document.open();
      terms_frame.value.contentWindow.document.write(termsText.value);
      terms_frame.value.contentWindow.document.close();
    });
  });
}
watch(
  () => $store.getters.compareOfferWait,
  (compareOfferWait) => {
    if (compareOfferWait === false) {
      view.value == "multioffer" || D2D.value == true
        ? updateStatus()
        : checkSample();
    }
  }
);

//* Lifecycle
onMounted(() => {
  offer_frame.value.contentWindow.document.open();
  offer_frame.value.contentWindow.document.write(offerText.value);
  offer_frame.value.contentWindow.document.close();

  terms_frame.value.contentWindow.document.open();
  terms_frame.value.contentWindow.document.write(termsText.value);
  terms_frame.value.contentWindow.document.close();
});
</script>

<style lang="sass">
@import '../styles/quasar.sass'
th
  font-weight: 700 !important
.consumption-card
  width: 90%
  margin: auto
.provider
  opacity: 0.4
  transition: 0.2s
.provider:hover
  opacity: 1
  transition: 0.1s
.selectedProvider
  transition: 0.2s
.icon
    font-size: 40px
    position: relative
    z-index: 2
    margin: auto
    width: 90px
    height: 90px
    line-height: 90px
    border-radius: 100%
    background-image: linear-gradient(to bottom, $primary, $secondary)
    vertical-align: middle
    border: none
</style>

<template>
  <div class="text-h5">Ingangsdatum</div>
  <div
    class="text-caption text-grey-9"
    style="max-width: 70%"
    v-html="startDateRules.message ?? ''"
  ></div>
  <div class="q-pt-sm text-caption" style="max-width: 70%">
    <q-toggle
      label="Geeft de Prospect toestemming om de ingangsdatum aan te passen?"
      color="green"
      :disable="!startDateRules.allowToggle || readonly"
      v-model="allowStartDate"
      true-value="Ja"
      false-value="Nee"
    />
  </div>

  <q-input
    hide-bottom-space
    dense
    filled
    readonly
    style="max-width: 70%"
    v-model="startDate"
    mask="##-##-####"
    :rules="['##-##-####', validateStartdate]"
    class="q-mb-none q-pb-none"
  >
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer" v-if="allowStartDate == 'Ja'">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-date
            :readonly="readonly"
            :key="startDateRules.minimalDate"
            v-model="startDate"
            mask="DD-MM-YYYY"
            :options="optionsFn"
            no-unset
          >
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { date } from "quasar";

const { formatDate, getMinDate, isValid, extractDate } = date;

//* Props
//* VueUse
const $store = useStore();

//* Refs
const optionsFn = (date) =>
  date >= startDateRules.value.minimalDate &&
  date <= startDateRules.value.maximalDate;

//* Computed
const projectSpecific = computed(() => $store.getters.projectSpecific);

const allowStartDate = computed({
  get() {
    return projectSpecific.value["Ingangsdatumaanpassen"] ?? "Nee";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "Ingangsdatumaanpassen",
      value: newVal,
    });

    if (newVal != "Ja") {
      startDate.value = formatDate(
        startDateRules.value.minimalDate,
        "DD-MM-YYYY"
      );
    }
  },
});

const startDate = computed({
  get() {
    return (
      projectSpecific.value["StartDate"] ??
      formatDate(startDateRules.value.minimalDate, "DD-MM-YYYY")
    );
  },
  set(newVal) {
    if (!isValid(formatDate(extractDate(newVal, "DD-MM-YYYY"), "YYYY-MM-DD"))) {
      newVal = formatDate(startDateRules.value.minimalDate, "DD-MM-YYYY");
    }
    $store.dispatch("setStartDate", newVal);

    $store.dispatch("setProjectSpecificValue", {
      key: "StartDate",
      value: newVal,
    });

    if (typeof projectSpecific.value["_ToAmMonth_"] != "undefined") {
      $store.dispatch("send_message", { message: "get_offers" });
    }
  },
});

const startDateRules = computed({
  get() {
    return $store.getters.startDateObj ?? {};
  },
  set(newVal) {
    $store.dispatch("setStartDateObj", newVal);
  },
});

const validateStartdate = (val) => {
  return (
    isValid(formatDate(extractDate(val, "DD-MM-YYYY"), "YYYY-MM-DD")) ||
    "Dit is geen valide datum"
  );
};

//* Conditions
if (!startDateRules.value.allowToggle) {
  allowStartDate.value = "Nee";
}

if (startDateRules.value?.startdate_untill) {
  startDate.value = formatDate(
    getMinDate(
      startDateRules.value.maximalDate,
      startDateRules.value.minimalDate
    ),
    "DD-MM-YYYY"
  );
}
</script>
<style lang="sass"></style>

<template>
  <q-card
    style="margin-top: -40px"
    :class="expandedTool != 'SJV' ? 'bg-grey-2' : ''"
  >
    <q-card-section class="text-center q-pa-none" @click="expandedTool = 'SJV'">
      <q-btn
        class="q-pa-md q-pt-xl"
        flat
        no-caps
        :disable="toolDisabled || readonly"
        :ripple="false"
        style="width: 100%; height: 100%"
      >
        <!-- <q-icon name="location_city"></q-icon> -->
        <div class="text-h6 text-bold">Historisch Verbruik</div>
      </q-btn>
      <!-- <div class="text-subtitle2">by John Doe</div> -->
    </q-card-section>

    <q-slide-transition>
      <div v-show="expandedTool == 'SJV' && !toolDisabled">
        <q-separator inset />
        <q-card-section class="text-caption text-center">
          Geeft de Prospect toestemming om het laatst bekende jaarverbruik te
          gebruiken voor deze aanbieding?
        </q-card-section>
        <q-card-section class="q-pt-none row q-gutter-md q-ml-none">
          <q-btn
            class="col-5 q-mx-auto"
            @click="allowSJV = 'Ja'"
            :color="allowSJV != 'Ja' ? 'grey-3' : 'primary'"
            :class="allowSJV != 'Ja' ? 'text-black' : ''"
            :ripple="false"
            no-caps
            >Ja</q-btn
          >
          <q-btn
            class="col-5 q-mx-auto"
            @click="allowSJV = 'Nee'"
            :color="allowSJV == 'Ja' ? 'grey-3' : 'primary'"
            :class="allowSJV == 'Ja' ? 'text-black' : ''"
            :ripple="false"
            no-caps
            >Nee</q-btn
          >
        </q-card-section>
        <q-card-section>
          <q-list>
            <q-item class="q-py-none consumption-item">
              <q-item-section class="q-pr-none" side>
                <q-icon color="black" name="bolt" />
              </q-item-section>

              <q-item-section>Normaal verbruik: </q-item-section>
              <q-item-section class="text-black" side>{{
                consumptionNormal
              }}</q-item-section>
            </q-item>
            <q-item class="q-py-none consumption-item">
              <q-item-section class="q-pr-none" side>
                <q-icon color="black" name="bolt" />
              </q-item-section>

              <q-item-section>Laag verbruik:</q-item-section>
              <q-item-section class="text-black" side>{{
                consumptionLow
              }}</q-item-section>
            </q-item>
            <q-item class="q-py-none consumption-item">
              <q-item-section class="q-pr-none" side>
                <q-icon color="black" name="whatshot" />
              </q-item-section>

              <q-item-section>Gas verbruik:</q-item-section>
              <q-item-section class="text-black" side>{{
                consumptionGas
              }}</q-item-section>
            </q-item>
            <q-item class="q-py-none consumption-item">
              <q-item-section class="q-pr-none" side>
                <q-icon color="black" name="bolt" />
              </q-item-section>

              <q-item-section>Teruglever verbruik:</q-item-section>
              <q-item-section class="text-black" side>{{
                consumptionRedelivery
              }}</q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-section>
          <q-btn
            no-caps
            @click="saveConsumption"
            :disable="allowSJV == 'Ja' ? false : true"
            color="primary"
            style="width: 100%"
            >Opslaan</q-btn
          >
        </q-card-section>
        <q-card-section class="text-caption text-center text-grey-8 q-pt-none">
          Het historisch verbruik is het laatst bekende jaarverbruik bij Essent,
          tijdens uw laatste contractperiode.
        </q-card-section>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const $store = useStore();

    const expandedTool = computed({
      get() {
        return $store.getters.expandedTool;
      },
      set(newVal) {
        $store.dispatch("setExpandedTool", newVal);
      },
    });

    const toolDisabled = computed(() => {
      // if ($store.getters.projectSpecific["SuccesfullGPV"] == "Ja") {
      //   return true;
      // }

      return false;
    });

    const allowSJV = computed({
      get() {
        return $store.getters.projectSpecific["toestemmingSJV"] ?? "Nee";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "toestemmingSJV",
          value: newVal,
        });
      },
    });

    const consumptionNormal = computed(() => {
      return $store.getters.projectSpecific["SJV_E_NORMAAL"] ?? "";
    });
    const consumptionLow = computed(() => {
      return $store.getters.projectSpecific["SJV_E_DAL"] ?? "";
    });
    const consumptionGas = computed(() => {
      return $store.getters.projectSpecific["SJV_G"] ?? "";
    });
    const consumptionRedelivery = computed(() => {
      return $store.getters.projectSpecific["SJV_E_EXPORT_TOTAAL"] ?? "";
    });

    return {
      expandedTool,
      allowSJV,
      consumptionNormal,
      consumptionLow,
      consumptionGas,
      toolDisabled,
      consumptionRedelivery,
    };
  },
  methods: {
    saveConsumption() {
      this.$store.dispatch("setProjectSpecificValues", {
        values: {
          "verbruik electra": this.consumptionNormal,
          "verbruik electra 2": this.consumptionLow,
          "verbruik gas": this.consumptionGas,
          "verbruik electra teruglevering": this.consumptionRedelivery,
          verbruikBerekeningsType: "SJV",
          verbruikBerekeningAangepast: "Nee",
          centraalregister: "Nee",
          SuccesfullSJV: "Ja",
          SuccesfullGPV: "Nee",
        },
      });
    },
  },
};
</script>

<style lang="sass">
.consumption-item
    min-height: 30px
</style>

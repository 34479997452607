<template>
  <q-form ref="formRef_step2_1">
    <div class="q-pb-md">
      <div class="q-pb-md row" v-if="!currentConsumptionBlock">
        <!-- GPV Tool -->
        <div
          v-if="consumptionTools.indexOf('GPV') != -1"
          :class="colSize"
          style="visibility: hidden"
        >
          <div class="icon text-white text-center shadow-1">
            <q-icon class="q-pb-sm" name="account_balance"></q-icon>
          </div>
          <GPV class="consumption-card"></GPV>
        </div>
        <!-- SJV Tool -->
        <div v-if="consumptionTools.indexOf('SJV') != -1" :class="colSize">
          <div class="icon text-white text-center shadow-1">
            <q-icon class="q-pb-sm" name="location_city"></q-icon>
          </div>
          <SJV class="consumption-card"></SJV>
        </div>
        <!-- Customer Information Tool -->
        <div
          v-if="consumptionTools.indexOf('CustomerInformation') != -1"
          :class="colSize"
        >
          <div class="icon text-white text-center shadow-1">
            <q-icon class="q-pb-sm" name="assignment_ind"></q-icon>
          </div>
          <customer-information class="consumption-card"></customer-information>
        </div>
        <!-- Calculator Tool -->
        <div
          v-if="consumptionTools.indexOf('Calculator') != -1"
          :class="colSize"
          style="visibility: hidden"
        >
          <div class="icon text-white text-center shadow-1">
            <q-icon class="q-pb-sm" name="calculate"></q-icon>
          </div>
          <calculator class="consumption-card"></calculator>
        </div>
      </div>
      <!-- Table Based Consumption -->
      <q-markup-table v-if="!screen_md" flat style="height: 175px">
        <thead>
          <tr>
            <th class="text-left">Aansluiting</th>
            <th class="text-center" v-if="!currentConsumptionBlock">
              EAN code
            </th>
            <th class="text-center">Verbruik Normaal</th>
            <th class="text-center">Verbruik Laag</th>
            <th class="text-center">Teruglevering</th>
            <th class="text-center" v-if="!currentConsumptionBlock">
              Einddatum
            </th>
            <th
              class="text-right"
              v-show="
                elecDateOption.popup ||
                gasDateOption.popup ||
                elecDateOption == 'Bekend' ||
                gasDateOption == 'Bekend'
              "
            >
              Datum
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="
              (currentConsumptionBlock && showElectricity) ||
              installationElectricity
            "
          >
            <td class="text-left">
              <q-icon name="bolt" style="font-size: 1.1rem" />Stroom
            </td>
            <td class="text-center" v-if="!currentConsumptionBlock">
              {{ installationElectricity }}
            </td>
            <td class="text-right">
              <q-input
                :readonly="readonly"
                outlined
                dense
                ref="consumptionNormalRef"
                :disable="allowManual != 'Ja' || !manualConsumption"
                :modelValue="
                  !manualConsumption
                    ? consumptionNormal
                    : consumptionNormalProxy
                "
                @change="consumptionNormalProxy = $event"
                placeholder="Verbruik Normaal"
                :rules="validate('requiredConsumption')"
                class="q-mb-none q-pb-none"
              />
            </td>
            <td class="text-right">
              <q-input
                :readonly="readonly"
                outlined
                dense
                :disable="allowManual != 'Ja' || !manualConsumption"
                :modelValue="
                  !manualConsumption ? consumptionLow : consumptionLowProxy
                "
                @change="consumptionLowProxy = $event"
                placeholder="Verbruik Laag"
                lazy-rules
                :rules="validate('consumption')"
                class="q-mb-none q-pb-none"
              />
            </td>
            <td class="text-right">
              <q-input
                :readonly="readonly"
                outlined
                dense
                ref="redeliveryRef"
                :disable="allowManual != 'Ja' || !manualConsumption"
                :modelValue="!manualConsumption ? redelivery : redeliveryProxy"
                @change="redeliveryProxy = $event"
                placeholder="Teruglevering"
                :rules="validate('consumption')"
                class="q-mb-none q-pb-none"
              />
            </td>
            <td>
              <q-select
                :readonly="readonly || currentConsumptionBlock"
                outlined
                dense
                v-model="elecDateOption"
                class="q-mb-none q-pb-none"
                map-options
                :options="options"
                emit-value
                :rules="validate('required')"
                v-if="!currentConsumptionBlock"
              >
              </q-select>
            </td>
            <td v-show="elecDateOption.popup || elecDateOption == 'Bekend'">
              <q-input
                hide-bottom-space
                dense
                filled
                readonly
                v-model="elecDate"
                mask="##-##-####"
                :rules="elecDateValidRule"
                class="q-mb-none q-pb-none"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="elecDate"
                        :readonly="readonly"
                        mask="DD-MM-YYYY"
                        today-btn
                        no-unset
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </td>
          </tr>
          <tr v-if="(currentConsumptionBlock && showGas) || installationGas">
            <td class="text-left">
              <q-icon name="whatshot" style="font-size: 1rem" /> Gas
            </td>
            <td class="text-center" v-if="!currentConsumptionBlock">
              {{ installationGas }}
            </td>
            <td class="text-right">
              <q-input
                :readonly="readonly"
                outlined
                dense
                ref="consumptionGasRef"
                :disable="allowManual != 'Ja' || !manualConsumption"
                :modelValue="
                  !manualConsumption ? consumptionGas : consumptionGasProxy
                "
                @change="consumptionGasProxy = $event"
                placeholder="Verbruik Normaal"
                lazy-rules
                :rules="validate('requiredConsumption')"
                class="q-mb-none q-pb-none"
              />
            </td>
            <td></td>
            <td></td>
            <td>
              <q-select
                outlined
                dense
                v-model="gasDateOption"
                map-options
                emit-value
                :options="options"
                class="q-mb-none q-pb-none"
                :rules="validate('required')"
                :readonly="currentConsumptionBlock"
                v-if="!currentConsumptionBlock"
              />
            </td>
            <td v-show="gasDateOption.popup || gasDateOption == 'Bekend'">
              <q-input
                hide-bottom-space
                dense
                filled
                readonly
                v-model="gasDate"
                mask="##-##-####"
                :rules="gasDateValidRule"
                class="q-mb-none q-pb-none"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="gasDate"
                        :readonly="readonly"
                        mask="DD-MM-YYYY"
                        today-btn
                        no-unset
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <!-- Card Based Consumption -->
      <div v-if="screen_md" class="row">
        <div
          v-if="currentConsumptionBlock || installationElectricity"
          class="col-6 q-pr-sm"
        >
          <q-list class="rounded-borders" bordered>
            <q-item
              class="text-white bg-primary"
              style="border-radius: 4px 4px 0 0"
            >
              <q-item-section>
                <q-item-label class="text-weight-bold text-center">
                  <q-icon name="bolt" style="font-size: 1.1rem" /> Stroom
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator inset />
            <q-item v-if="!currentConsumptionBlock">
              <q-item-section>
                <q-item-label class="text-weight-bold"> EAN Code </q-item-label>
              </q-item-section>

              <q-item-section>
                {{ installationElectricity }}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Verbruik Normaal
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-input
                  :readonly="readonly"
                  outlined
                  dense
                  ref="consumptionNormalRef"
                  :disable="allowManual != 'Ja' || !manualConsumption"
                  :modelValue="
                    !manualConsumption
                      ? consumptionNormal
                      : consumptionNormalProxy
                  "
                  @change="consumptionNormalProxy = $event"
                  placeholder="Verbruik Normaal"
                  :rules="validate('requiredConsumption')"
                  class="q-mb-none q-pb-none"
                />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Verbruik Laag
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-input
                  :readonly="readonly"
                  outlined
                  dense
                  :disable="allowManual != 'Ja' || !manualConsumption"
                  :modelValue="
                    !manualConsumption ? consumptionLow : consumptionLowProxy
                  "
                  @change="consumptionLowProxy = $event"
                  placeholder="Verbruik Laag"
                  lazy-rules
                  :rules="validate('consumption')"
                  class="q-mb-none q-pb-none"
                />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Teruglevering
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-input
                  :readonly="readonly"
                  outlined
                  dense
                  ref="redeliveryRef"
                  :disable="allowManual != 'Ja' || !manualConsumption"
                  :modelValue="
                    !manualConsumption ? redelivery : redeliveryProxy
                  "
                  @change="redeliveryProxy = $event"
                  placeholder="Teruglevering"
                  :rules="validate('consumption')"
                  class="q-mb-none q-pb-none"
                />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Einddatum
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-select
                  :readonly="readonly || currentConsumptionBlock"
                  outlined
                  dense
                  v-model="elecDateOption"
                  class="q-mb-none q-pb-none"
                  map-options
                  :options="options"
                  emit-value
                  :rules="validate('required')"
                >
                </q-select>
              </q-item-section>
            </q-item>
            <q-slide-transition>
              <q-item
                v-show="elecDateOption.popup || elecDateOption == 'Bekend'"
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold"> Datum </q-item-label>
                </q-item-section>

                <q-item-section>
                  <q-input
                    hide-bottom-space
                    dense
                    filled
                    readonly
                    v-model="elecDate"
                    mask="##-##-####"
                    :rules="elecDateValidRule"
                    class="q-mb-none q-pb-none"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="elecDate"
                            :readonly="readonly"
                            mask="DD-MM-YYYY"
                            today-btn
                            no-unset
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </q-item-section>
              </q-item>
            </q-slide-transition>
          </q-list>
        </div>
        <div
          v-if="(currentConsumptionBlock && showGas) || installationGas"
          class="col-6 q-pr-sm"
        >
          <q-list class="rounded-borders" bordered>
            <q-item
              class="text-white bg-primary"
              style="border-radius: 4px 4px 0 0"
            >
              <q-item-section>
                <q-item-label class="text-weight-bold text-center">
                  <q-icon name="whatshot" style="font-size: 1rem" /> Gas
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-separator inset />
            <q-item v-if="!currentConsumptionBlock">
              <q-item-section>
                <q-item-label class="text-weight-bold"> EAN Code </q-item-label>
              </q-item-section>

              <q-item-section>
                {{ installationGas }}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Verbruik Normaal
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-input
                  :readonly="readonly"
                  outlined
                  dense
                  ref="consumptionGasRef"
                  :disable="allowManual != 'Ja' || !manualConsumption"
                  :modelValue="
                    !manualConsumption ? consumptionGas : consumptionGasProxy
                  "
                  @change="consumptionGasProxy = $event"
                  placeholder="Verbruik Normaal"
                  lazy-rules
                  :rules="validate('requiredConsumption')"
                  class="q-mb-none q-pb-none"
                />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-bold">
                  Einddatum
                </q-item-label>
              </q-item-section>

              <q-item-section>
                <q-select
                  outlined
                  dense
                  v-model="gasDateOption"
                  map-options
                  emit-value
                  :options="options"
                  class="q-mb-none q-pb-none"
                  :rules="validate('required')"
                  :readonly="currentConsumptionBlock"
                />
              </q-item-section>
            </q-item>
            <q-slide-transition>
              <q-item v-show="gasDateOption.popup || gasDateOption == 'Bekend'">
                <q-item-section>
                  <q-item-label class="text-weight-bold"> Datum </q-item-label>
                </q-item-section>

                <q-item-section>
                  <q-input
                    hide-bottom-space
                    dense
                    filled
                    readonly
                    v-model="gasDate"
                    mask="##-##-####"
                    :rules="gasDateValidRule"
                    class="q-mb-none q-pb-none"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="gasDate"
                            :readonly="readonly"
                            mask="DD-MM-YYYY"
                            today-btn
                            no-unset
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </q-item-section>
              </q-item>
            </q-slide-transition>
          </q-list>
        </div>
      </div>
      <div class="q-pt-sm row">
        <div class="col-2">
          <q-btn
            :disabled="readonly"
            v-show="
              (consumptionGas != '' || consumptionNormal != '') &&
              !disableChangeUsage
            "
            color="primary"
            :ripple="false"
            @click="
              () => {
                manualConsumption = !manualConsumption;
                consumptionLowProxy = consumptionLow;
                consumptionNormalProxy = consumptionNormal;
                consumptionGasProxy = consumptionGas;
                redeliveryProxy = redelivery;
              }
            "
            no-caps
            >Verbruik wijzigen</q-btn
          >
        </div>
        <div class="col-6">
          <q-toggle
            :disable="readonly"
            v-show="manualConsumption"
            class="q-pl-lg q-pt-none"
            true-value="Ja"
            false-value="Nee"
            v-model="allowManual"
            color="green"
            label="Gaat de Prospect akkoord met het wijzigen van het verbruik?"
          />
        </div>
        <div class="col-4">
          <q-btn
            color="primary"
            class="float-right"
            v-show="allowManual == 'Ja'"
            :ripple="false"
            @click="saveManualConsumption"
            no-caps
            >Opslaan</q-btn
          >
        </div>
      </div>
      <div class="row q-pt-xl">
        <div :class="colSizeProvider" v-if="!currentConsumptionBlock">
          <div class="q-pb-none">
            <span class="text-h6 text-bold">Huidige Leverancier</span>
          </div>
          <div>
            <q-img
              v-for="provider in providers"
              :key="provider.value"
              :src="provider.url"
              class="q-mr-md cursor-pointer"
              :img-class="
                selectedProvider == provider.value
                  ? 'selectedProvider'
                  : 'provider'
              "
              @click="selectedProvider = provider.value"
              spinner-color="white"
              style="max-width: 98px"
            />
          </div>
        </div>
        <div :class="colSizeProvider" v-if="!currentConsumptionBlock">
          <div class="q-pb-none">
            <span class="text-h6 text-bold">Huidig type Contract</span>
          </div>
          <div>
            <q-img
              v-for="contract in contracts"
              :key="contract.value"
              :src="contract.url"
              class="q-mr-md cursor-pointer"
              :img-class="
                selectedContract == contract.value
                  ? 'selectedProvider'
                  : 'provider'
              "
              @click="selectedContract = contract.value"
              spinner-color="white"
              style="max-width: 98px"
            />
          </div>
        </div>
      </div>
      <StepperNavigation
        class="row justify-end"
        @next-step="nextStep()"
        @previous-step="previousStep()"
        :loading="load"
      />
    </div>
  </q-form>
</template>

<script>
// import { useQuasar } from "quasar";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useQuasar, date } from "quasar";
import SJV from "./CurrentConsumption/SJV.vue";
import CustomerInformation from "./CurrentConsumption/CustomerInformation.vue";
import Calculator from "./CurrentConsumption/Calculator.vue";
import GPV from "./CurrentConsumption/GPV.vue";
import StepperNavigation from "@/components/StepperNavigation.vue";
import { validate, validator } from "../plugins/validation.js";

export default {
  components: {
    SJV,
    CustomerInformation,
    Calculator,
    GPV,
    StepperNavigation,
  },

  setup() {
    const $q = useQuasar();
    const $store = useStore();

    const manualConsumption = ref(false);

    const gasDateProxy = ref("");
    const elecDateProxy = ref("");

    const consumptionLowProxy = ref("");
    const consumptionNormalProxy = ref("");
    const consumptionGasProxy = ref("");
    const redeliveryProxy = ref("");
    const redeliveryRef = ref(null);
    const consumptionNormalRef = ref(null);
    const consumptionGasRef = ref(null);
    const formRef_step2_1 = ref(null);

    const options = [
      { label: "Bekend, namelijk:", value: "Bekend", popup: true },
      { label: "Onbekend", value: "Onbekend", popup: false },
      { label: "Onbepaalde tijd", value: "Onbepaalde tijd", popup: false },
    ];

    const view = computed(() => {
      return $store.getters.view;
    });

    const consumptionTools = computed(() => {
      console.log($store.getters.projectSpecific["consumptionTools"]);
      return (
        $store.getters.projectSpecific["consumptionTools"] ?? [
          "CustomerInformation",
          "Calculator",
        ]
      );
    });

    const allowManual = computed({
      get() {
        return $store.getters.projectSpecific["Toestemingverbruik"] ?? "Nee";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "Toestemingverbruik",
          value: newVal,
        });
      },
    });

    const typeFuse = computed({
      get() {
        return $store.getters.projectSpecific["type meter"];
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "type meter",
          value: newVal,
        });
      },
    });

    const elecDateOption = computed({
      get() {
        return $store.getters.projectSpecific["einddatum_contract"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "einddatum_contract",
          value: newVal,
          validated: validator("required", newVal),
        });

        if (gasDateOption.value == "") {
          $store.dispatch("setProjectSpecificValue", {
            key: "einddatum_contract2",
            value: newVal,
            validated: validator("required", newVal),
          });
        }
      },
    });
    const elecDateValidRule = [
      (val) =>
        elecDateOption.value != "Bekend" ||
        (val !== null && val !== "") ||
        "Vul een contract einde datum in",
      (val) =>
        /^((0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d)?$/.test(
          val
        ) || "Ongeldige datum, formaat moet DD-MM-JJJJ zijn",
    ];
    const elecDate = computed({
      get() {
        return $store.getters.projectSpecific["einddatumElektra"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "einddatumElektra",
          value: newVal,
          validated: validator(elecDateValidRule, newVal),
        });

        if (gasDate.value == "") {
          $store.dispatch("setProjectSpecificValue", {
            key: "einddatumGas",
            value: newVal,
            validated: validator(gasDateValidRule, newVal),
          });
        }
      },
    });

    const gasDateOption = computed({
      get() {
        return $store.getters.projectSpecific["einddatum_contract2"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "einddatum_contract2",
          value: newVal,
        });
      },
    });
    const gasDateValidRule = [
      (val) =>
        gasDateOption.value.value != "Bekend" ||
        (val !== null && val !== "") ||
        "Vul een contract einde datum in",
      (val) =>
        /^((0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d)?$/.test(
          val
        ) || "Ongeldige datum, formaat moet DD-MM-JJJJ zijn",
    ];
    const gasDate = computed({
      get() {
        return $store.getters.projectSpecific["einddatumGas"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "einddatumGas",
          value: newVal,
          validated: validator(gasDateValidRule, newVal),
        });
      },
    });

    const selectedProvider = computed({
      get() {
        return (
          $store.getters.projectSpecific["concurrent electra"] ??
          $store.getters.projectSpecific["concurrent gas"] ??
          ""
        );
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValues", {
          values: {
            "concurrent electra": newVal,
            "concurrent gas": newVal,
          },
        });
      },
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const selectedContract = computed({
      get() {
        return $store.getters.projectSpecific["huidigtypecontract"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "huidigtypecontract",
          value: newVal,
        });
      },
    });

    const loading = computed(() => {
      return $store.getters.loading ?? false;
    });
    const load = computed(() => {
      return $store.getters.pendingRequests >= 1 ? true : false;
    });

    const installationGas = computed(() => {
      return $store.getters.projectSpecific["EAN Gas"];
    });

    const installationElectricity = computed(() => {
      return $store.getters.projectSpecific["EAN Elektra"];
    });

    const moving = computed(() => {
      return $store.getters.projectSpecific["contract_with_another_supplier"];
    });

    const mono = computed(() => {
      return $store.getters.projectSpecific["MONO"] == "true" ? true : false;
    });

    const showGas = computed(
      () => $store.getters.projectSpecific["verbruik gas"] || false
    );
    const showElectricity = computed(() => {
      return (
        $store.getters.projectSpecific["verbruik electra"] ||
        $store.getters.projectSpecific["verbruik electra 2"] ||
        false
      );
    });

    const residence = computed(() => {
      return $store.getters.projectSpecific["residential"];
    });

    const screen_md = computed(() => {
      return $q.screen.lt.md;
    });

    const colSize = computed(() => {
      return $q.screen.lt.md ? "col-6 q-pb-lg" : "col-3";
    });

    const colSizeProvider = computed(() => {
      return $q.screen.lt.md ? "col-12" : "col-6";
    });

    const consumptionNormal = computed({
      get() {
        return $store.getters.projectSpecific["verbruik electra"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "verbruik electra",
          value: newVal,
        });
      },
    });
    const consumptionLow = computed({
      get() {
        return $store.getters.projectSpecific["verbruik electra 2"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "verbruik electra 2",
          value: newVal,
        });
      },
    });
    const consumptionGas = computed({
      get() {
        return $store.getters.projectSpecific["verbruik gas"] ?? "";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "verbruik gas",
          value: newVal,
        });
      },
    });
    const redelivery = computed(() => {
      return (
        $store.getters.projectSpecific["verbruik electra teruglevering"] ?? ""
      );
    });

    const currentConsumptionBlock = computed(() =>
      view.value == "retention" ? true : false
    );

    // electra
    if (view.value == "retention") {
      elecDateOption.value = {
        label: "Onbekend",
        value: "Onbekend",
        popup: false,
      };
    }

    // gas
    if (view.value == "retention" && showGas.value) {
      gasDateOption.value = {
        label: "Onbekend",
        value: "Onbekend",
        popup: false,
      };
    }

    consumptionLowProxy.value = consumptionLow.value;
    consumptionNormalProxy.value = consumptionNormal.value;
    consumptionGasProxy.value = consumptionGas.value;
    redeliveryProxy.value = redelivery.value;
    const providers = [
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "eon-logo.png",
        value: "E.ON",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "electrabel-logo.png",
        value: "Electrabel",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "eneco-logo.png",
        value: "Eneco",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "vattenfall-logo.png",
        value: "Vattenfall",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "oxxio-logo.png",
        value: "Oxxio",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "overig-logo.png",
        value: "Overig",
      },
    ];

    const contracts = [
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "zakelijk-logo.png",
        value: "Zakelijk",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "particulier-logo.png",
        value: "Particulier",
      },
      {
        url: process.env.VUE_APP_IMAGE_BASE_URL + "onbekend-logo.png",
        value: "Onbekend",
      },
    ];

    const disableChangeUsage = computed(() =>
      $store.getters.projectSpecific["Verbruik_wijzigen_disabled"] &&
      $store.getters.projectSpecific["Verbruik_wijzigen_disabled"] == true
        ? true
        : false
    );

    return {
      currentConsumptionBlock,
      formRef_step2_1,
      residence,
      moving,
      loading,
      load,
      installationElectricity,
      installationGas,
      options,
      colSize,
      colSizeProvider,
      consumptionNormal,
      consumptionLow,
      consumptionGas,
      redelivery,
      consumptionNormalProxy,
      consumptionLowProxy,
      consumptionGasProxy,
      redeliveryProxy,
      gasDateOption,
      gasDate,
      screen_md,
      gasDateProxy,
      elecDateOption,
      elecDate,
      elecDateProxy,
      providers,
      selectedProvider,
      contracts,
      selectedContract,
      manualConsumption,
      allowManual,
      consumptionTools,
      redeliveryRef,
      consumptionNormalRef,
      consumptionGasRef,
      typeFuse,
      elecDateValidRule,
      gasDateValidRule,
      readonly,
      view,
      mono,
      showGas,
      showElectricity,
      disableChangeUsage,
    };
  },
  methods: {
    nextStep() {
      if (this.installationElectricity) {
        this.consumptionNormalRef.validate();
      }
      if (this.installationGas) {
        this.consumptionGasRef.validate();
      }

      if (
        (this.installationElectricity && this.consumptionNormalRef.hasError) ||
        (this.installationGas && this.consumptionGasRef.hasError)
      ) {
        return;
      }

      this.formRef_step2_1.validate().then((success) => {
        if (success) {
          this.$store.dispatch("incrementCurrentStep");
          if (!this.installationElectricity && this.view != "retention") {
            console.log(
              "No Electricity installation; clearing electricity consumption"
            );
            this.$store.dispatch("setProjectSpecificValues", {
              values: {
                "verbruik electra": "",
                "verbruik electra 2": "",
                "verbruik electra teruglevering": "",
              },
            });
          }
          if (!this.installationGas && this.view != "retention") {
            console.log("No Gas installation; clearing gas consumption");
            this.$store.dispatch("setProjectSpecificValues", {
              values: {
                "verbruik gas": "",
              },
            });
          }
          if (this.consumptionLow != "") {
            this.typeFuse = "Dubbele meter";
          } else {
            this.typeFuse = "Enkele meter";
          }

          this.$store.dispatch("send_message", {
            message: "save_consumption",
          });
          if (this.view == "energyportal") {
            const timeStamp = date.addToDate(new Date(), { days: 14 });
            const formattedString = date.formatDate(timeStamp, "YYYY-MM-DD");
            this.$store.dispatch("send_message", {
              message: "save_start_date",
              body: {
                electricity: formattedString,
                gas: formattedString,
              },
            });
          }
        }
      });
    },
    previousStep() {
      this.$store.dispatch("decrementCurrentStep");
    },
    saveManualConsumption() {
      let values = {
        "verbruik electra": this.consumptionNormalProxy,
        "verbruik electra 2": this.consumptionLowProxy,
        "verbruik gas": this.consumptionGasProxy,
        "verbruik electra teruglevering": this.redeliveryProxy,
      };

      if (
        this.consumptionLowProxy != this.consumptionLow ||
        this.consumptionNormalProxy != this.consumptionNormal ||
        this.consumptionGasProxy != this.consumptionGas ||
        this.redeliveryProxy != this.redelivery
      ) {
        values.verbruikBerekeningAangepast = "Ja";
      }

      this.$store.dispatch("setProjectSpecificValues", {
        values,
      });
      this.manualConsumption = false;
    },
    validate,
  },
};
</script>

<style lang="sass">
@import '../styles/quasar.sass'
th
  font-weight: 700 !important
.consumption-card
  width: 90%
  margin: auto
.provider
  opacity: 0.4
  transition: 0.2s
.provider:hover
  opacity: 1
  transition: 0.1s
.selectedProvider
  transition: 0.2s
.icon
    font-size: 40px
    position: relative
    z-index: 2
    margin: auto
    width: 90px
    height: 90px
    line-height: 90px
    border-radius: 100%
    background-image: linear-gradient(to bottom, $primary, $secondary)
    vertical-align: middle
    border: none
</style>

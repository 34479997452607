<template>
  <q-form ref="formRef_3_1">
    <div class="q-gutter-y-md" v-if="panels !== false">
      <ProductSelection />
      <div class="row">
        <div class="col-6">
          <ManualInstallment
            v-if="view != 'retention'"
            :manualInstallmentRules="manualInstallmentRules"
          />
        </div>
        <div class="col-6">
          <StartDate v-if="view != 'retention'" />
        </div>
      </div>
    </div>
    <StepperNavigation
      class="row justify-end q-pb-md"
      @next-step="nextStep()"
      @previous-step="previousStep()"
    />
  </q-form>
</template>

<script setup>
import { ref, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { getStartDateObj } from "../plugins/start-date.js";

import ProductSelection from "./Offers/ProductSelection.vue";
import ManualInstallment from "./Offers/ManualInstallment.vue";
import StartDate from "./Offers/Startdate.vue";
import StepperNavigation from "@/components/StepperNavigation.vue";

//* VueUse
const $store = useStore();
const $q = useQuasar();

//* Refs
const formRef_3_1 = ref(null);
const manualInstallmentRules = [
  (val) => val != "" || "Er is nog geen termijn bedrag bekend",
  (val) =>
    /^[1-9][0-9]*(,[0-9]{2})?$/.test(val) || "Vul een bedrag met komma in",
  (val) =>
    val.replace(",", ".") > projectSpecific.value["_ToAmMonth_"] ||
    val.replace(",", ".") >
      projectSpecific.value["_ToAmMonth_"] -
        Math.abs(projectSpecific.value["_ToAmMonth_"]) * 0.1 ||
    "Het nieuw ingevoerde termijn bedrag wijkt teveel af van het origineel.",
  (val) =>
    val.replace(",", ".") >= 10 ||
    "Het termijn bedrag moet boven de 10 euro zijn.",
];

//* Computed
const viewMode = computed(() => $store.getters.viewMode);
const view = computed(() => $store.getters.view);
const panels = computed(() => $store.getters?.products?.options ?? false);
const projectSpecific = computed(() => $store.getters.projectSpecific);
const selectedProduct = computed(
  () => projectSpecific.value["selectedProduct"]
);
const startDateObj = computed({
  get() {
    return $store.getters.startDateObj ?? {};
  },
  set(newVal) {
    $store.dispatch("setStartDateObj", newVal);
  },
});

//*  Methods
function clearPrevOffer() {
  $store.dispatch("clearPrevOffer");
}

function previousStep() {
  $store.dispatch("decrementCurrentStep");
  clearPrevOffer();
}

function nextStep() {
  if (viewMode.value === true) {
    $store.dispatch("incrementCurrentStep");
    return;
  }
  if (!selectedProduct.value?.product) {
    $q.notify({
      type: "negative",
      message: "Geen product geselecteerd",
      position: "top",
    });
    return;
  }
  if (
    Object.entries($store.getters.poll).length != 0 ||
    $store.getters.pendingRequests > 0
  ) {
    $q.notify({
      type: "negative",
      message: "AOM nog niet afgerond",
      position: "top",
    });
    return;
  }
  if (
    selectedProduct.value.product.campaignid !=
    projectSpecific.value["_Campaign_code"]
  ) {
    $q.notify({
      type: "negative",
      message: "AOM niet afgerond",
      position: "top",
    });
    return;
  }
  formRef_3_1.value.validate().then((success) => {
    if (success) {
      $store.dispatch("incrementCurrentStep");
      $store.dispatch("send_message", {
        message: "save_start_date_and_select_proposition",
      });
      $store.dispatch("send_message", { message: "save_budget_bill_amount" });
      if (view.value == "retention") {
        $store.dispatch("send_message", {
          message: "select_proposition",
        });
      }
    }
  });
}

//* Conditions
startDateObj.value = getStartDateObj();
</script>

<style lang="sass">
.q-table tbody td
  height: 55px
.selected
  transition: 6s
</style>

<template>
  <q-card class="column justify-between">
    <q-card-section
      class="bg-primary text-white shadow-1 q-py-sm absolute"
      style="width: 100%"
    >
      <div class="text-h6 text-weight-bold">Overzicht</div>
    </q-card-section>
    <q-card-section class="q-pa-none q-pt-xl">
      <q-list>
        <q-item class="q-my-sm">
          <q-item-section>
            <q-item-label>Aansluiting</q-item-label>
            <q-item-label caption>
              {{ street }} {{ housenumber }}
            </q-item-label>
            <q-item-label caption> {{ city }} </q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 1">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Huidige Situatie</q-item-label>
            <q-item-label caption v-show="consumptionNormal != ''"
              >Normaal: {{ consumptionNormal }}
            </q-item-label>
            <q-item-label caption v-show="consumptionLow != ''"
              >Laag: {{ consumptionLow }}
            </q-item-label>
            <q-item-label caption v-show="consumptionGas != ''"
              >Gas: {{ consumptionGas }}
            </q-item-label>
            <q-item-label caption v-show="redelivery != ''"
              >Teruglevering: {{ redelivery }}
            </q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 2">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>
        <q-item class="q-my-sm">
          <q-item-section>
            <q-item-label>Aanbod</q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 3">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>

        <q-item class="q-my-sm">
          <q-item-section>
            <q-item-label>Opt-in</q-item-label>
            <q-item-label caption v-show="homeAdvantage != ''"
              >Info Thuisvoordeel: {{ homeAdvantage }}
            </q-item-label>
            <q-item-label caption v-show="applyHomeAdvantage != ''"
              >Aanmelden Thuisvoordeel: {{ applyHomeAdvantage }}
            </q-item-label>
            <q-item-label caption v-show="infoHomeApp != ''"
              >Info thuisapp: {{ infoHomeApp }}
            </q-item-label>
            <q-item-label caption v-show="comfortSolution != ''"
              >Comfortoplossing: {{ comfortSolution }}
            </q-item-label>
            <q-item-label caption v-show="newsletter != ''"
              >Nieuwsbrief: {{ newsletter }}
            </q-item-label>
            <q-item-label caption v-show="offersPartners != ''"
              >Aanbiedingen Partners: {{ offersPartners }}
            </q-item-label>
            <q-item-label caption v-show="optinEnergyHome != ''"
              >Optin Energy Home: {{ optinEnergyHome }}
            </q-item-label>
            <q-item-label caption v-show="optinEnergyEG != ''"
              >Optin Energy e+g: {{ optinEnergyEG }}
            </q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 4">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>

        <q-item class="q-my-sm">
          <q-item-section>
            <q-item-label>Prospectgegevens</q-item-label>
            <q-item-label caption v-show="initials != ''">
              {{ initials }}
            </q-item-label>
            <q-item-label caption v-show="prefix != ''">
              {{ prefix }}
            </q-item-label>
            <q-item-label caption v-show="lastName != ''">
              {{ lastName }}
            </q-item-label>
            <q-item-label caption v-show="phone != ''">
              {{ phone }}
            </q-item-label>
            <q-item-label caption v-show="email != ''">
              {{ email }}
            </q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 5">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>

        <q-item class="q-my-sm">
          <q-item-section>
            <q-item-label>Samenvatting</q-item-label>
          </q-item-section>

          <q-item-section side top v-show="currentStep > 6">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <q-card-section class="q-pa-none">
      <q-list>
        <q-separator></q-separator>
        <q-item>
          <q-item-section>
            <q-item-label caption> CTPID: {{ ctpid }} </q-item-label>
            <q-item-label caption> Project: {{ project }} </q-item-label>
            <q-item-label caption> Record ID: {{ recordId }} </q-item-label>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-btn
              class="text-weight-bolder"
              icon="refresh"
              color="primary"
              label="Refresh"
              no-caps
              @click="reload()"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>
<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

//* VueUse
const $store = useStore();

//* Refs

//* Computed
const currentStep = computed(() => $store.getters.record.current_step);
const initials = computed(() => $store.getters.record.Initialen);
const prefix = computed(() => $store.getters.record.Tussenvoegsel);
const lastName = computed(() => $store.getters.record.Achternaam);
const phone = computed(() => $store.getters.record.Telefoon);
const email = computed(() => $store.getters.record.Email);
const ctpid = computed(() => $store.getters.steamVelden.RecordID);
const project = computed(() => $store.getters.steamVelden.project);
const recordId = computed(() => $store.getters.record.id);
const street = computed(() => $store.getters.record.Straat);
const housenumber = computed(() => $store.getters.record.Huisnummer);

const city = computed(() => $store.getters.record.Woonplaats);
const consumptionNormal = computed(
  () => $store.getters.projectSpecific["verbruik electra"] ?? ""
);
const consumptionLow = computed(
  () => $store.getters.projectSpecific["verbruik electra 2"] ?? ""
);
const consumptionGas = computed(
  () => $store.getters.projectSpecific["verbruik gas"] ?? ""
);
const redelivery = computed(
  () => $store.getters.projectSpecific["verbruik electra teruglevering"] ?? ""
);
const homeAdvantage = computed(() =>
  $store.getters.projectSpecific["ENPLUS_1"] == "false" ||
  $store.getters.projectSpecific["ENPLUS_1"] == null ||
  $store.getters.projectSpecific["ENPLUS_1"] == ""
    ? ""
    : $store.getters.projectSpecific["Info_Thuisvoordeel"] == true
    ? "Ja"
    : "Nee"
);
const applyHomeAdvantage = computed(() =>
  $store.getters.projectSpecific["ENPLUS_2"] == "false" ||
  $store.getters.projectSpecific["ENPLUS_2"] == null ||
  $store.getters.projectSpecific["ENPLUS_2"] == ""
    ? ""
    : $store.getters.projectSpecific["Aanmelden_Thuisvoordeel"] == true
    ? "Ja"
    : "Nee"
);
const infoHomeApp = computed(() =>
  $store.getters.projectSpecific["ENPLUS_3"] == "false" ||
  $store.getters.projectSpecific["ENPLUS_3"] == null ||
  $store.getters.projectSpecific["ENPLUS_3"] == ""
    ? ""
    : $store.getters.projectSpecific["Info_Thuisapp"] == true
    ? "Ja"
    : "Nee"
);
const comfortSolution = computed(() =>
  $store.getters.projectSpecific["ENPLUS_4"] == "false" ||
  $store.getters.projectSpecific["ENPLUS_4"] == null ||
  $store.getters.projectSpecific["ENPLUS_4"] == ""
    ? ""
    : $store.getters.projectSpecific["comfortoplossingen"] == true
    ? "Ja"
    : "Nee"
);
//? default state is set to true when ENPLUS_5 is not mentioned in the URL. So instead we want to check if the value IS false instead of falsy, also instead of checking if its false OR null we check if it false AND not null to somewhat keep consistency
const newsletter = computed(() =>
  $store.getters.projectSpecific["ENPLUS_5"] == "false" &&
  $store.getters.projectSpecific["ENPLUS_5"] != null &&
  $store.getters.projectSpecific["ENPLUS_5"] != ""
    ? ""
    : $store.getters.projectSpecific["nieuwsbrief"] == true
    ? "Ja"
    : "Nee"
);
const offersPartners = computed(() =>
  $store.getters.projectSpecific["ENPLUS_6"] == "false" ||
  $store.getters.projectSpecific["ENPLUS_6"] == null ||
  $store.getters.projectSpecific["ENPLUS_6"] == ""
    ? ""
    : $store.getters.projectSpecific["aanbiedingenpartners"] == true
    ? "Ja"
    : "Nee"
);
//? default state is set to true when OPTIN_1 is not mentioned in the URL. So instead we want to check if the value IS false instead of falsy, also instead of checking if its false OR null we check if it false AND not null to somewhat keep consistency
const optinEnergyHome = computed(() =>
  $store.getters.projectSpecific["OPTIN_1"] == "false" &&
  $store.getters.projectSpecific["OPTIN_1"] != null &&
  $store.getters.projectSpecific["OPTIN_1"] != ""
    ? ""
    : $store.getters.projectSpecific["OptinEnergyHome"] == true
    ? "Ja"
    : "Nee"
);
//? default state is set to true when OPTIN_2 is not mentioned in the URL. So instead we want to check if the value IS false instead of falsy, also instead of checking if its false OR null we check if it false AND not null to somewhat keep consistency
const optinEnergyEG = computed(() =>
  $store.getters.projectSpecific["OPTIN_2"] == "false" &&
  $store.getters.projectSpecific["OPTIN_2"] != null &&
  $store.getters.projectSpecific["OPTIN_2"] != ""
    ? ""
    : $store.getters.projectSpecific["OptinEnergyEG"] == true
    ? "Ja"
    : "Nee"
);

function reload() {
  window.location.reload();
}
</script>
<style lang=""></style>

import script from "./Retention.vue?vue&type=script&setup=true&lang=js"
export * from "./Retention.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QToolbarTitle,QImg,QBtn,QStepper,QCard,QStep});

<template>
  <div class="q-pb-md row" style="margin-left: -36px">
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_1">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="info"></q-icon>
      </div>
      <home-advantage class="consumption-card"></home-advantage>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_2">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="local_offer"></q-icon>
      </div>
      <apply-home-advantage class="consumption-card"></apply-home-advantage>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_3">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="phone_android"></q-icon>
      </div>
      <info-home-app class="consumption-card"></info-home-app>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_4">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="home"></q-icon>
      </div>
      <comfort-solution class="consumption-card"></comfort-solution>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_5">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="feed"></q-icon>
      </div>
      <newsletter class="consumption-card"></newsletter>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="ENPLUS_6">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="groups"></q-icon>
      </div>
      <offers-partners class="consumption-card"></offers-partners>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="OPTIN_1">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="home"></q-icon>
      </div>
      <energy-home class="consumption-card"></energy-home>
    </div>
    <div :class="colSize" class="q-pb-md" v-if="OPTIN_2">
      <div class="icon text-white text-center shadow-1">
        <q-icon class="q-pb-sm" name="groups"></q-icon>
      </div>
      <energy-EG class="consumption-card"></energy-EG>
    </div>
  </div>
  <StepperNavigation
    class="row justify-end q-pb-md"
    @next-step="nextStep()"
    @previous-step="previousStep()"
  />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import HomeAdvantage from "./EnergyPlus/HomeAdvantage.vue";
import ApplyHomeAdvantage from "./EnergyPlus/ApplyHomeAdvantage.vue";
import InfoHomeApp from "./EnergyPlus/InfoHomeApp.vue";
import ComfortSolution from "./EnergyPlus/ComfortSolution.vue";
import Newsletter from "./EnergyPlus/Newsletter.vue";
import OffersPartners from "./EnergyPlus/OffersPartners.vue";
import EnergyHome from "./EnergyPlus/Optin/EnergyHome.vue";
import EnergyEG from "./EnergyPlus/Optin/EnergyEG.vue";
import StepperNavigation from "@/components/StepperNavigation.vue";
import { useQuasar } from "quasar";

export default {
  components: {
    HomeAdvantage,
    ApplyHomeAdvantage,
    InfoHomeApp,
    ComfortSolution,
    Newsletter,
    OffersPartners,
    EnergyHome,
    EnergyEG,
    StepperNavigation,
  },
  setup() {
    const $q = useQuasar();
    const $store = useStore();

    const colSize = computed(() => {
      return $q.screen.lt.md ? "col-6" : "col-3";
    });

    const ENPLUS_1 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_1 ?? "false").toLowerCase() !=
        "false"
      );
    });
    const ENPLUS_2 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_2 ?? "false").toLowerCase() !=
        "false"
      );
    });
    const ENPLUS_3 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_3 ?? "false").toLowerCase() !=
        "false"
      );
    });
    const ENPLUS_4 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_4 ?? "false").toLowerCase() !=
        "false"
      );
    });
    const ENPLUS_5 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_5 ?? "true").toLowerCase() !=
        "false"
      );
    });
    const ENPLUS_6 = computed(() => {
      return (
        ($store.getters.projectSpecific.ENPLUS_6 ?? "false").toLowerCase() !=
        "false"
      );
    });
    const OPTIN_1 = computed(() => {
      return (
        ($store.getters.projectSpecific.OPTIN_1 ?? "true").toLowerCase() !=
        "false"
      );
    });
    const OPTIN_2 = computed(() => {
      return (
        ($store.getters.projectSpecific.OPTIN_2 ?? "true").toLowerCase() !=
        "false"
      );
    });

    function previousStep() {
      $store.dispatch("decrementCurrentStep");
    }

    function nextStep() {
      $store.dispatch("incrementCurrentStep");
    }

    return {
      colSize,
      ENPLUS_1,
      ENPLUS_2,
      ENPLUS_3,
      ENPLUS_4,
      ENPLUS_5,
      ENPLUS_6,
      OPTIN_1,
      OPTIN_2,
      previousStep,
      nextStep,
    };
  },
};
</script>

<style lang="sass">
@import '../styles/quasar.sass'
th
  font-weight: 700 !important
.consumption-card
  width: 90%
  margin: auto
.provider
  opacity: 0.4
  transition: 0.2s
.provider:hover
  opacity: 1
  transition: 0.1s
.selectedProvider
  transition: 0.2s
.icon
    font-size: 40px
    position: relative
    z-index: 2
    margin: auto
    width: 90px
    height: 90px
    line-height: 90px
    border-radius: 100%
    background-image: linear-gradient(to bottom, $primary, $secondary)
    vertical-align: middle
    border: none
</style>

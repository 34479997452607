<template>
  <q-card
    style="margin-top: -40px"
    :class="expandedTool != 'GPV' ? 'bg-grey-2' : ''"
  >
    <q-card-section class="text-center q-pa-none" @click="expandedTool = 'GPV'">
      <q-btn
        class="q-pa-md q-pt-xl"
        flat
        no-caps
        :disable="toolDisabled || readonly"
        :ripple="false"
        style="width: 100%; height: 100%"
      >
        <!-- <q-icon name="location_city"></q-icon> -->
        <div class="text-h6 text-bold">GPV Tool</div>
      </q-btn>
      <!-- <div class="text-subtitle2">by John Doe</div> -->
    </q-card-section>

    <q-slide-transition>
      <div v-show="expandedTool == 'GPV' && !toolDisabled">
        <q-separator inset />
        <q-card-section class="text-caption text-center">
          Geeft de Prospect toestemming om een persoonlijke schatting vanuit
          {{ energySupplier }} te gebruiken voor deze aanbieding?
        </q-card-section>
        <q-card-section class="q-pt-none row q-gutter-md q-ml-none">
          <q-btn
            class="col-5 q-mx-auto"
            @click="allowGPV = 'Ja'"
            :color="allowGPV != 'Ja' ? 'grey-3' : 'primary'"
            :class="allowGPV != 'Ja' ? 'text-black' : ''"
            :ripple="false"
            no-caps
            >Ja</q-btn
          >
          <q-btn
            class="col-5 q-mx-auto"
            @click="allowGPV = 'Nee'"
            :color="allowGPV == 'Ja' ? 'grey-3' : 'primary'"
            :class="allowGPV == 'Ja' ? 'text-black' : ''"
            :ripple="false"
            no-caps
            >Nee</q-btn
          >
        </q-card-section>
        <q-card-section class="q-mt-sm">
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="typeFuse"
            :options="fuseOptions"
            label="Heeft u een enkele of dubbele meter?"
          />
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="residents"
            :options="residentsOptions"
            label="Hoeveel personen wonen/gaan er wonen op dit adres?"
          />
          <q-select
            :readonly="readonly"
            v-show="false"
            :stack-label="stacked"
            map-options
            dense
            outlined
            v-model="vacancy"
            :options="vacancyOptions"
            label="Is het pand op dit moment bewoond?"
          />
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-btn
            no-caps
            @click="saveConsumption"
            :disable="allowGPV == 'Ja' && residents && typeFuse ? false : true"
            color="primary"
            style="width: 100%"
            :loading="loading.gpv ?? false"
            >Checken</q-btn
          >
        </q-card-section>
        <q-card-section class="text-caption text-center text-grey-8 q-pt-none">
          Op basis van publiek beschikbare data over het type huis op het adres
          (zoals bouwjaar, inhoud, energielabel, etc.) en het aantal personen
          dat op het adres woont maken we een zo goed mogelijke inschatting van
          het verwacht verbruik voor dat adres.
        </q-card-section>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

export default {
  setup() {
    const $store = useStore();
    const $q = useQuasar();

    const loading = computed({
      get() {
        return $store.getters.loading ?? false;
      },
      set(newVal) {
        $store.dispatch("setLoading", {
          key: newVal[0],
          value: newVal[1],
        });
      },
    });

    const expandedTool = computed({
      get() {
        return $store.getters.expandedTool;
      },
      set(newVal) {
        $store.dispatch("setExpandedTool", newVal);
      },
    });

    const toolDisabled = computed(() => {
      if ($store.getters.projectSpecific["SuccesfullSJV"] == "Ja") {
        return true;
      }

      return false;
    });

    const stacked = computed(() => {
      return $q.screen.lt.md ? false : true;
    });

    const allowGPV = computed({
      get() {
        return $store.getters.projectSpecific["toestemmingGPV"] ?? "Nee";
      },
      set(newVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "toestemmingGPV",
          value: newVal,
        });
      },
    });

    const typeFuse = ref("");
    const typeFuseComputed = computed(() => {
      return $store.getters.projectSpecific["type meter"];
    });
    typeFuse.value = typeFuseComputed.value;
    const fuseOptions = [
      {
        label: "Enkele meter",
        value: "Enkele meter",
      },
      {
        label: "Dubbele meter",
        value: "Dubbele meter",
      },
    ];

    const vacancy = ref("false");
    const vacancyComputed = computed(() => {
      return $store.getters.projectSpecific["gpv_bewoond"];
    });
    const vacancyOptions = [
      {
        label: "Nee",
        value: true,
      },
      {
        label: "Ja",
        value: false,
      },
    ];

    const residents = ref("");
    const residentsComputed = computed(() => {
      return $store.getters.projectSpecific["NumberOfPersons"];
    });
    residents.value = residentsComputed.value;
    const residentsOptions = [
      {
        label: "1 Persoon",
        value: "1",
      },
      {
        label: "2 Personen",
        value: "2",
      },
      {
        label: "3 Personen",
        value: "3",
      },
      {
        label: "4 Personen",
        value: "4",
      },
      {
        label: "5 Personen",
        value: "5",
      },
      {
        label: "6 Personen",
        value: "6",
      },
    ];

    const energySupplier = computed(() => {
      return $store.getters.projectSpecific["Energieleverancier"] ?? "Essent";
    });

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    return {
      expandedTool,
      allowGPV,
      typeFuse,
      typeFuseComputed,
      fuseOptions,
      vacancy,
      vacancyComputed,
      vacancyOptions,
      residents,
      residentsComputed,
      residentsOptions,
      energySupplier,
      stacked,
      toolDisabled,
      readonly,
      loading,
    };
  },
  methods: {
    saveConsumption() {
      this.loading = ["gpv", true];
      this.$store.dispatch("send_message", {
        message: "get_expected_consumption",
        body: this.residents.value,
      });

      this.$store.dispatch("setProjectSpecificValues", {
        values: {
          verbruikBerekeningsType: "GPV",
          verbruikBerekeningAangepast: "Nee",
          centraalregister: "Nee",
          SuccesfullSJV: "Nee",
          SuccesfullGPV: "Ja",
          NumberOfPersons: this.residents.value ?? this.residentsComputed.value,
          gpv_bewoond: false,
          "type meter": this.typeFuse.value ?? this.typeFuseComputed,
        },
      });
    },
  },
};
</script>

<style lang="sass">
@import '../../styles/quasar.sass'
.consumption-item
    min-height: 30px
</style>

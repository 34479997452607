<template>
  <q-card style="margin-top: -40px">
    <q-card-section
      class="text-center q-pa-none"
      @click="expandedTool = 'CustomerInformation'"
      style="width: 100%; height: 100%"
    >
      <q-btn
        class="q-pb-md q-pt-xl q-px-none no-pointer-events"
        flat
        no-caps
        disable
        :ripple="false"
        style="width: 100%; height: 100%"
      >
        <!-- <q-icon name="location_city"></q-icon> -->
        <div class="text-h6 text-bold">Aanmelden Thuisvoordeel</div>
      </q-btn>

      <q-toggle
        :disable="readonly"
        class="q-pb-sm"
        v-model="toggle"
        true-value="Ja"
        false-value="Nee"
        size="xl"
        checked-icon="check"
        :color="toggle == 'Ja' ? 'green' : 'red'"
        unchecked-icon="clear"
        keep-color
      />
    </q-card-section>
  </q-card>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const $store = useStore();
    const toggle = computed({
      get() {
        return $store.getters.projectSpecific["Aanmelden_Thuisvoordeel"] == true
          ? "Ja"
          : "Nee";
      },
      set(newVal) {
        newVal = newVal == "Ja" ? true : false;
        $store.dispatch("setProjectSpecificValue", {
          key: "Aanmelden_Thuisvoordeel",
          value: newVal,
        });
      },
    });

    return {
      toggle,
    };
  },
};
</script>

<style lang="sass">
@import '../../styles/quasar.sass'
.consumption-item
    min-height: 30px
</style>

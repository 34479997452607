<template>
  <q-card
    style="margin-top: -40px"
    :class="expandedTool != 'Calculator' ? 'bg-grey-2' : ''"
  >
    <q-card-section
      class="text-center q-pa-none"
      @click="expandedTool = 'Calculator'"
    >
      <q-btn
        class="q-pa-md q-pt-xl"
        flat
        no-caps
        :disable="toolDisabled || readonly"
        :ripple="false"
        style="width: 100%; height: 100%"
      >
        <!-- <q-icon name="location_city"></q-icon> -->
        <div class="text-h6 text-bold">Calculator</div>
      </q-btn>
      <!-- <div class="text-subtitle2">by John Doe</div> -->
    </q-card-section>

    <q-slide-transition>
      <div v-show="expandedTool == 'Calculator' && !toolDisabled">
        <q-separator inset />
        <q-card-section>
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="typeFuse"
            :options="fuseOptions"
            label="Heeft u een enkele of dubbele meter?"
          />
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="residents"
            :options="residentsOptions"
            label="Hoeveel personen wonen er op dit adres?"
          />
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="consumptionType"
            :options="consumptionOptions"
            label="Hoe omschrijft u uw energieverbruik?"
          />
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            class="q-mb-md"
            v-model="premiseType"
            :options="premiseOptions"
            label="Wat voor type woning heeft u?"
          />
          <q-select
            :readonly="readonly"
            :stack-label="stacked"
            map-options
            dense
            outlined
            v-model="constructionYear"
            :options="constructionOptions"
            label="Wat is het bouwjaar van de woning?"
          />
        </q-card-section>
        <q-card-section>
          <q-btn
            no-caps
            @click="saveConsumption"
            color="primary"
            style="width: 100%"
            >Berekenen</q-btn
          >
        </q-card-section>
      </div>
    </q-slide-transition>
  </q-card>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

export default {
  setup() {
    const $store = useStore();
    const $q = useQuasar();

    const expandedTool = computed({
      get() {
        return $store.getters.expandedTool;
      },
      set(newVal) {
        $store.dispatch("setExpandedTool", newVal);
      },
    });

    const stacked = computed(() => {
      return $q.screen.lt.md ? false : true;
    });

    const toolDisabled = computed(() => {
      if (
        $store.getters.projectSpecific["SuccesfullSJV"] == "Ja" ||
        $store.getters.projectSpecific["SuccesfullGPV"] == "Ja"
      ) {
        return true;
      }

      return false;
    });

    const typeFuse = ref("");
    const typeFuseComputed = computed({
      get() {
        return $store.getters.projectSpecific["type meter"];
      },
      set(newVal) {
        typeFuse.value = newVal;
      },
    });
    typeFuse.value = typeFuseComputed.value;
    const fuseOptions = [
      {
        label: "Enkele meter",
        value: "Enkele meter",
      },
      {
        label: "Dubbele meter",
        value: "Dubbele meter",
      },
    ];

    const residents = ref("");
    const residentsComputed = computed({
      get() {
        return $store.getters.projectSpecific["NumberOfPersons"];
      },
      set(newVal) {
        residents.value = newVal;
      },
    });
    residents.value = residentsComputed.value;
    const residentsOptions = [
      {
        label: "1 Persoon",
        value: "1",
      },
      {
        label: "2 Personen",
        value: "2",
      },
      {
        label: "3 Personen",
        value: "3",
      },
      {
        label: "4 Personen",
        value: "4",
      },
      {
        label: "5 Personen",
        value: "5",
      },
      {
        label: "6 Personen",
        value: "6",
      },
      {
        label: "7 Personen",
        value: "7",
      },
      {
        label: "8 Personen",
        value: "8",
      },
      {
        label: "9 Personen",
        value: "9",
      },
      {
        label: "10 Personen",
        value: "10",
      },
    ];

    const consumptionType = ref("");
    const consumptionTypeComputed = computed({
      get() {
        return $store.getters.projectSpecific["ConsumerType"];
      },
      set(newVal) {
        consumptionType.value = newVal;
      },
    });
    consumptionType.value = consumptionTypeComputed.value;
    const consumptionOptions = [
      {
        label: "Laag",
        value: "1",
      },
      {
        label: "Normaal",
        value: "2",
      },
      {
        label: "Hoog",
        value: "3",
      },
    ];

    const premiseType = ref("");
    const premiseTypeComputed = computed({
      get() {
        return $store.getters.projectSpecific["PremiseType"];
      },
      set(newVal) {
        premiseType.value = newVal;
      },
    });
    premiseType.value = premiseTypeComputed.value;
    const premiseOptions = [
      {
        label: "Vrijstaand",
        value: "1",
      },
      {
        label: "2-onder-een-kap",
        value: "2",
      },
      {
        label: "Rij-hoekwoning",
        value: "3",
      },
      {
        label: "Rij-tussenwoning",
        value: "4",
      },
      {
        label: "Flat/appartement",
        value: "5",
      },
      {
        label: "Garage",
        value: "6",
      },
      {
        label: "Onbekend",
        value: "7",
      },
    ];

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const constructionYear = ref("");
    const constructionYearComputed = computed({
      get() {
        return $store.getters.projectSpecific["ConstructionYear"];
      },
      set(newVal) {
        constructionYear.value = newVal;
      },
    });
    constructionYear.value = constructionYearComputed.value;
    const constructionOptions = [
      {
        label: "Voor 1945",
        value: "1",
      },
      {
        label: "Tussen 1945 en 1980",
        value: "2",
      },
      {
        label: "Na 1980",
        value: "3",
      },
      {
        label: "Onbekend",
        value: "4",
      },
    ];

    return {
      expandedTool,
      typeFuse,
      typeFuseComputed,
      fuseOptions,
      residents,
      residentsComputed,
      residentsOptions,
      consumptionType,
      consumptionTypeComputed,
      consumptionOptions,
      premiseType,
      premiseTypeComputed,
      premiseOptions,
      constructionYear,
      constructionYearComputed,
      constructionOptions,
      stacked,
      toolDisabled,
      readonly,
    };
  },
  methods: {
    saveConsumption() {
      this.$store.dispatch("setProjectSpecificValues", {
        values: {
          NumberOfPersons: this.residents.value ?? this.residentsComputed,
          ConsumerType:
            this.consumptionType.value ?? this.consumptionTypeComputed,
          DayTimeUsage: "",
          PremiseType: this.premiseType.value ?? this.premiseTypeComputed,
          ConstructionYear:
            this.constructionYear.value ?? this.constructionYearComputed,
          Energieleverancier:
            this.$store.getters.projectSpecific.Energieleverancier,
          "type meter": this.typeFuse.value ?? this.typeFuseComputed,
          verbruikBerekeningsType: "Calculatie",
          verbruikBerekeningAangepast: "Nee",
          centraalregister: "Nee",
        },
      });
      this.$store.dispatch("getCalculatedUsage", {
        typeFuse: this.typeFuse.value ?? this.typeFuseComputed,
        NumberOfPersons: this.residents.value ?? this.residentsComputed,
        ConsumerType:
          this.consumptionType.value ?? this.consumptionTypeComputed,
        DayTimeUsage: "",
        PremiseType: this.premiseType.value ?? this.premiseTypeComputed,
        ConstructionYear:
          this.constructionYear.value ?? this.constructionYearComputed,
        Energieleverancier:
          this.$store.getters.projectSpecific.Energieleverancier,
        record_id: this.$store.getters.record.id,
      });
    },
  },
};
</script>

<style lang="sass">
@import '../../styles/quasar.sass'
.consumption-item
    min-height: 30px
</style>
